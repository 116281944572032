<template>
  <div>
    <div class="row" v-show="pageIndex==1">
      <div class="col-md-6 mr-0">
        <input type="checkbox" v-model="movement.excludeFromBill" id="excludeFromBill" name="excludeFromBill" v-bind:disabled="movement.status != Enums.MovementStatus.Undefined.value || movement.status != Enums.MovementStatus.AwaitingAnalystReview.value" />&nbsp;&nbsp;<label for="excludeFromBill" class="font-weight-bold"> Exclude from bill</label><br>
      </div>
      <br />
      <div class="col-md-6 mr-5">
        <div class="drawers" role="tablist">
          <div id="movementDatadrawerHeading" class="panel-heading" role="tab" v-bind:aria-selected="movement.status == Enums.MovementStatus.Undefined.value && currentUser.canEnterBaseMovementDetails">
            <h4>movement data</h4>
          </div>
          <div class="panel-content" role="tabpanel" v-bind:aria-expanded="movement.status == Enums.MovementStatus.Undefined.value && currentUser.canEnterBaseMovementDetails">
            <fieldset v-bind:disabled="isDisabled || !((movement.status == Enums.MovementStatus.Undefined.value && currentUser.canEnterBaseMovementDetails) || (movement.status == Enums.MovementStatus.AwaitingAnalystReview.value && currentUser.canSubmitMovement))">
              <div class="form-group row mt-4">
                <label for="vessel" class="col-md-4">vessel</label>
                <select class="form-control col-md-6" id="vessel" v-model="movement.vessel" required errorLabel="Vessel">
                  <option value="" disabled>-- Select a vessel --</option>
                  <option v-for="vessel in activeVessels" :value="vessel">{{ vessel.name }}</option>
                </select>
                <div class="col-md-4 invalid-feedback">
                  Please select a vessel.
                </div>
              </div>
              <div class="form-group row mt-4">
                <label for="userGroupId" class="col-md-4">user group</label>
                <select class="form-control col-md-6" id="userGroupId" v-model="movement.userGroupId" v-bind:required="requireOptionalFields" errorLabel="User Group" v-bind:disabled="movement.excludeFromBill">
                  <option value="" disabled>-- Select a user group --</option>
                  <option v-for="userGroup in activeUserGroups" :value="userGroup.id">{{ userGroup.name }}</option>
                </select>
                <div class="col-md-4 invalid-feedback">
                  Please select a user group.
                </div>
              </div>
              <div class="form-group row mt-4">
                <label class="col-4">mode</label>
                <input type="radio" id="movementMode" name="movementMode" :value="Enums.MovementModes.Departure.value" v-model="movement.mode" required errorLabel="Movement mode">
                <label class="col-2" for="movementMode">{{ Enums.MovementModes.Departure.description }}</label><br>
                <input type="radio" id="movementMode" name="movementMode" :value="Enums.MovementModes.Arrival.value" v-model="movement.mode" required errorLabel="Movement mode">
                <label class="col-2" for="movementMode">{{ Enums.MovementModes.Arrival.description }}</label><br>
                <!--<select class="form-control col-md-4" v-model="movement.mode" required>
      <option value="" disabled>-- Select a movement mode --</option>
      <option v-for="mode in movementModes" :value="mode.value">{{ mode.description }}</option>
    </select>
    <div class="col-md-4 invalid-feedback">
      Please select a movement mode.
    </div>-->
              </div>
              <!--<div class="form-group row mt-4">
    <label for="movementTime" class="col-4">{{ movement.mode ? movement.mode.description : 'arrival / departure' }} time</label>
    <input type="time" id="movementTime" class="form-control col-md-3" v-model="movement.time" required :min="minimumMovementTime" :max="maximumTime" errorLabel="Movement Time" />
    <div class="col-md-4 invalid-feedback">
      please select a valid time.
    </div>
  </div>-->
              <div class="form-group row mt-4">
                <label for="fromLocationId" class="col-4">location from</label>
                <select class="form-control col-md-6" id="fromLocationId" v-model="movement.fromLocationId" required  errorLabel="From"> <!--v-bind:disabled="movement.mode === Enums.MovementModes.Departure.value"-->
                  <option value="" disabled>-- Select a location --</option>
                  <option v-for="location in locationsFrom" :value="location.id">{{ location.name }}</option>
                </select>
                <!--<div class="col-md-4 invalid-feedback">
      Please select a location.
    </div>-->
              </div>
              <div class="form-group row mt-4">
                <label for="toLocationId" class="col-4">location to</label>
                <select class="form-control col-md-6" id="toLocationId" v-model="movement.toLocationId" required  errorLabel="To"> <!--v-bind:disabled="movement.mode === Enums.MovementModes.Arrival.value"-->
                  <option value="" disabled>-- Select a location --</option>
                  <option v-for="location in locationsTo" :value="location.id">{{ location.name }}</option>
                </select>
                <div class="col-md-4 invalid-feedback">
                  Please select a location.
                </div>
              </div>
              <div class="form-group row mt-4">
                <label for="departureDate" class="col-4">departure date from {{ locationFromName }}</label>
                <!--<input type="date" id="departureDate" class="form-control col-md-3" v-model="movement.departureDate" v-bind:required="!movement.arrivalDate || movement.departureTime" errorLabel="Departure Date" />
  <input type="time" id="departureTime" class="form-control col-md-3" v-model="movement.departureTime" v-bind:required="movement.departureDate" errorLabel="Departure Time" />-->
                <!--<span> {{ movement.date | toLongDateString }}</span>-->

                <input type="date" id="departureDate" class="form-control col-md-3" v-model="movement.departureDate" v-bind:disabled="movement.mode == Enums.MovementModes.Arrival.value" errorLabel="Departure Date" />
                <input type="time" id="departureTime" class="form-control col-md-3" v-model="movement.departureTime" v-bind:disabled="movement.mode == Enums.MovementModes.Arrival.value" errorLabel="Departure Time" />

                <div class="col-md-4 invalid-feedback">
                  please select a valid date / time.
                </div>
              </div>
              <div class="form-group row mt-4">
                <label for="arrivalDate" class="col-4">arrival date at {{ locationToName }}</label>
                <input type="date" id="arrivalDate" class="form-control col-md-3" v-model="movement.arrivalDate" v-bind:disabled="movement.mode == Enums.MovementModes.Departure.value" errorLabel="Arrival Date" />
                <input type="time" id="arrivalTime" class="form-control col-md-3" v-model="movement.arrivalTime" v-bind:disabled="movement.mode == Enums.MovementModes.Departure.value" errorLabel="Arrival Time" />
                <!--<span> {{ movement.date | toLongDateString }}</span>-->
                <div class="col-md-4 invalid-feedback">
                  please select a valid date / time.
                </div>
              </div>
              <div class="form-group row mt-4">
                <label for="reason" class="col-4">movement reason</label>
                <select id="reason" class="form-control col-md-4" v-model="movement.reason" v-bind:required="requireOptionalFields" errorLabel="Reason" v-bind:disabled="movement.excludeFromBill">
                  <option value="" disabled>-- select a reason --</option>
                  <option v-for="reason in Enums.MovementReasons" :value="reason.value">{{ reason.description }}</option>
                </select>
                <div class="col-md-4 invalid-feedback">
                  please select a reason.
                </div>
              </div>
              <div class="form-group row mt-4">
                <label for="chargeCodeSearch" class="col-4">charge code</label>
                <employee-autocomplete label="Cost center" :items="searchedChargeCodes" id="chargeCodeSearch" :sData="movement.chargeCode" :employeeSearchStartedEvent="chargeCodeSearchStartedEvent" :employeeSelectedEvent="chargeCodeSearchSelectedEvent" :employeeSearchClearedEvent="chargeCodeSearchClearedEvent" idField="costCenter" descriptionField="display" v-bind:showLabel="false" v-bind:isRequired="requireOptionalFields" v-bind:isDisabled="movement.excludeFromBill" />
                <!--<input type="text" id="chargeCode" class="form-control col-md-6" v-model="movement.chargeCode" required />-->
                <div class="col-md-4 invalid-feedback">
                  please input a valid charge code.
                </div>
              </div>
              <div class="form-group row mt-4">
                <label for="barge" class="col-4">barge</label>
                <select id="barge" class="form-control col-md-4" v-model="movement.barge" errorLabel="Barge" v-bind:disabled="movement.excludeFromBill">
                  <option value="" disabled>-- Select a barge --</option>
                  <option v-for="barge in activeBarges" :value="barge">{{ barge.name }}</option>
                </select>
                <span v-if="movement.barge.id">
                  CBM: <span class="font-weight-bold">{{ movement.barge.cbm }}</span> &nbsp;&nbsp; Tonnage: <span class="font-weight-bold">{{ movement.barge.tonnage }}</span>
                </span>
                <!--<div class="col-md-4 invalid-feedback">
      Please select a barge.
    </div>-->
              </div>
              <div class="form-group row mt-4">
                <label for="numberOfPassengers" class="col-4">no. of passengers</label>
                <input type="number" id="numberOfPassengers" class="form-control col-md-3" v-model="movement.numberOfPassengers" v-bind:required="requireOptionalFields" min="0" errorLabel="Number of Passengers" v-bind:disabled="movement.excludeFromBill" />
                <div class="col-md-4 invalid-feedback">
                  please input a valid number.
                </div>
              </div>
            </fieldset>
          </div>
          <div class="panel-heading" role="tab" v-bind:aria-selected="movement.status == Enums.MovementStatus.AwaitingFuelAnalyst.value && currentUser.canEnterFuelDetails">
            <h4>fuel data</h4>
          </div>
          <div class="panel-content" role="tabpanel" v-bind:aria-expanded="currentUser.canEnterFuelDetails">
            <fieldset v-bind:disabled="isDisabled || !(currentUser.canEnterFuelDetails || currentUser.canSubmitMovement)">
              <div class="form-group row mt-4">
                <!--<input type="number" placeholder="enter fuel ROB" class="form-control col-md-3" v-model="movement.fuelROB" errorLabel="fuel ROB" v-bind:required="movement.fuelDensity" />-->
                <!--<label for="fuelROB" class="col-4">fuel ROB / density</label>-->
                <!--<floating-field type="number" id="fuelROB" step="0.01" label="fuel ROB" additionalClass="col-md-3" v-model="movement.fuelROB" :showLabel="true" v-bind:isRequired="false"></floating-field>-->
                <input type="number" placeholder="enter fuel ROB" class="form-control col-md-3" v-model="movement.fuelROB" errorLabel="fuel ROB" />
                <select id="fuelDensity" class="form-control col-md-3" v-model="movement.fuelDensity" errorLabel="fuel density">
                  <option value="">-- Select the fuel density --</option>
                  <option value="0.78">0.78</option>
                  <option value="0.85">0.85</option>
                </select>
                <!--<floating-field type="number" id="fuelDensity" step="0.01" label="fuel density" additionalClass="col-md-3" v-model="movement.fuelDensity" :showLabel="true" v-bind:isRequired="false"></floating-field>-->
                <!--<input type="number" id="fuelROB" class="form-control col-md-3" v-model="movement.fuelROB" required min="1" step="0.1" placeholder="fuel ROB" errorLabel="fuel ROB" />
  <input type="number" id="fuelDensity" class="form-control col-md-3" v-model="movement.fuelDensity" required min="1" step="0.1" placeholder="fuel density" errorLabel="fuel density" />-->
                <!--<div class="col-md-4 invalid-feedback">
    please input a valid number.
  </div>-->
              </div>
            </fieldset>
          </div>
          <div class="panel-heading" role="tab" v-bind:aria-selected="movement.status == Enums.MovementStatus.AwaitingCargoAnalyst.value && currentUser.canEnterCargoDetails">
            <h4>cargo data</h4>
          </div>
          <div class="panel-content" role="tabpanel" v-bind:aria-expanded="movement.status == Enums.MovementStatus.AwaitingCargoAnalyst.value && currentUser.canEnterCargoDetails">
            <fieldset v-bind:disabled="isDisabled || !(currentUser.canEnterCargoDetails || currentUser.canSubmitMovement)">
              <div class="form-group row mt-4">
                <floating-field v-if="false" type="number" id="tenFtCargoQty" value="0" label="10ft Qty" additionalClass="col-md-2" v-model="movement.tenFeetCargoQuantity" :showLabel="true" v-bind:isRequired="false"></floating-field>
                <floating-field v-if="false" type="number" id="twentyFtCargoQty" value="0" label="20ft Qty" additionalClass="col-md-2" v-model="movement.twentyFeetCargoQuantity" :showLabel="true" v-bind:isRequired="false"></floating-field>
                <floating-field v-if="false" type="number" id="fortyFtCargoQty" value="0" label="40ft Qty" additionalClass="col-md-2" v-model="movement.fortyFeetCargoQuantity" :showLabel="true" v-bind:isRequired="false"></floating-field>
                <floating-field type="number" id="materialCargoTonnage" step="0.01" label="material. cargo tonnage" additionalClass="col-md-5" v-model="movement.materialCargoTonnage" :showLabel="true" v-bind:isRequired="false" v-bind:disabled="movement.vessel.vesselTypeId === 2"></floating-field>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div id="calculatedFieldsDiv" class="col-5 mr-0" v-show="!movement.excludeFromBill">
        <div class="drawers" role="tablist">
          <div class="panel-heading" role="tab">
            Expected Invoice Parameters
          </div>
          <div class="panel-content" role="tabpanel">
            <div class="form-group row mt-4">
              <label class="col-4">veseel GRT</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">{{ movement.vessel.grt | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">pob rate</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ invoiceCalculator.pobRate }} / PAX</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">pilotage multiplier</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">{{ invoiceCalculator.pilotageMultiplierRate | toNumericDisplay}}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">conservancy multiplier</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">{{ invoiceCalculator.conservancyMultiplierRate | toNumericDisplay}}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">footage multiplier</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">{{ invoiceCalculator.footageMultiplierPercentRate | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">light dues rate</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ invoiceCalculator.lightDuesRate | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">overtime rate</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ invoiceCalculator.overtimeRate | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">weekend / holiday rate</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ invoiceCalculator.publicHolidayRate | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">garbage scavending rate</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ invoiceCalculator.garbageScavendingRate | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">tax rate</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">{{ invoiceCalculator.taxRatePercentRate | toNumericDisplay }}%</span>
            </div>
          </div>
          <!--<div class="panel-heading" role="tab" v-show="movement.status == Enums.MovementStatus.AwaitingAnalystReview.value">
            Expected Invoice Values
          </div>
          <div class="panel-content" role="tabpanel" v-show="movement.status == Enums.MovementStatus.AwaitingAnalystReview.value">
            <div class="form-group row mt-4">
              <label class="col-4">vessel GRT</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">{{ movement.vessel.grt | toNumericDisplayWithNoFractionalLimit }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">fuel tonnage</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">{{ movement.fuelTonnage | toNumericDisplayWithNoFractionalLimit }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">chargable tonnage</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">{{ movement.chargeableTonnage | toNumericDisplayWithNoFractionalLimit }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">pob</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ movement.pobCharge | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">pilotage</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ movement.pilotageCharge | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">conservancy</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ movement.conservancyCharge | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">footage</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ movement.footageCharge | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">light dues</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ movement.lightDues | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">weekend / public holiday</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ movement.weekendOrPublicHolidayCharge | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">overtime</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ movement.overtimeCharge | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">garbage scavending</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ movement.garbageScavendingCharge | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">total (before VAT)</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ movement.totalBeforeVAT | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">VAT</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ movement.vat | toNumericDisplay }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-4">total (after VAT)</label>
              <span id="vesselGRT" class="col-md-4 font-weight-bold">${{ movement.totalAfterVAT | toNumericDisplay }}</span>
            </div>
          </div>-->
          </div>
        </div>
    </div>
    <div v-show="pageIndex==2">
      <fieldset v-bind:disabled="isDisabled">
        <div class="row">
          <div class="col-12" mr-0>
            <vue-dropzone id="attachmentDropzone" v-bind:class="{ disabled: isDisabled }" ref="supportingDocumentsDropzone" :options="dropzoneOptions"></vue-dropzone>
          </div>
          <p class="mt-1" v-if="availableSupportingDocuments && availableSupportingDocuments.length">
            <div class="badge badge-secondary m-2 p-2" v-for="supportingDocument in availableSupportingDocuments" :key="supportingDocument.id">
              <span style="cursor: pointer" @click="downloadDocument(movement.voyageId, movement.id, supportingDocument.id, supportingDocument.originalDocumentName)">{{ supportingDocument.originalDocumentName }}</span><span class="ml-3" style="cursor: pointer;" @click="removeDocument(supportingDocument.id)">&#x2715</span>
            </div>
          </p>
        </div>
      </fieldset>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .icon {
    color: #fff;
    background-color: #2a31fe;
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 100%;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
    box-shadow: inset -1px -1px 1px 0px rgba(0,0,0,0.25);
  }

  .modal-body{
      height: 80%
  }

</style>
<script>
  import { mapState, mapGetters } from 'vuex'

  import Enums from '@/utils/enums'
  import { toNumericDisplay, toNumericDisplayWithNoFractionalLimit, toWholeNumberDisplay } from '@/filters'

  import { voyageService, chargeCodeService } from "@/services"

  import Events from '@/utils/events'
  import eventBus from '@/utils/eventBus'

  import EmployeeAutocomplete from '@/components/ui/EmployeeAutocomplete'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import AppAlert from '@/components/ui/AppAlert'
  import FloatingField from '@/components/ui/FloatingField'


  export default {
    name: 'MovementEntryForm',
    components: {
      AppAlert,
      FloatingField,
      EmployeeAutocomplete,
      vueDropzone: vue2Dropzone
    },
    props: ['movement', 'voyage', 'invoiceCalculator', 'isDisabled', 'pageIndex', 'alert', 'location', 'isSubmittingVoyage', "requireOptionalFields", 'emptyVessel', 'emptyBarge'],
    filters: {
      toNumericDisplay,
      toNumericDisplayWithNoFractionalLimit,
      toWholeNumberDisplay
    },
    data: () => ({
      Enums: Enums,
      numberOfItemsPerPage: 10,
      dropzoneOptions: {
        paramName: "files",
        url: '/',
        maxFiles: 10,
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: true,
        timeout: 0,
        dictDefaultMessage: 'Drag and drop supporting documents here or click to upload.',
        acceptedFiles: 'image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        init: function () {
          this.on("error", function (file) { if (!file.accepted) this.removeFile(file); });
        }
      },
      chargeCodeSearchClearedEvent: 'chargeCodeClearedEvent',
      chargeCodeSearchStartedEvent: 'chargeCodeStartedEvent',
      chargeCodeSearchSelectedEvent: 'chargeCodeSelectedEvent',
      searchedChargeCodes: []
    }),
    computed: {
      ...mapGetters([
        'activeVessels',
        'activeUserGroups',
        'activeLocations',
        'activeBarges',
        'allRates'
      ]),
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        locations: state => state.locationModule.locations
      }),
      locationsFrom() {
        const self = this
        if (self.movement.mode == Enums.MovementModes.Departure.value) {
          this.movement.fromLocationId = self.location.id
          return [self.location]
        }
        if (self.location) {
          return self.activeLocations.filter((x) => x.id != self.location.id)
        }

        return []
      },
      locationsTo() {
        const self = this
        if (self.movement.mode == Enums.MovementModes.Arrival.value) {
          this.movement.toLocationId = self.location.id
          return [self.location]
        }
        if (self.movement.fromLocationId) {
          return self.activeLocations.filter((x) => x.id != self.movement.fromLocationId)
        }
        return []
      },
      locationFromName() {
        if (this.movement.fromLocationId) {
          return this.locations.filter((x) => x.id == this.movement.fromLocationId)[0].name
        }
        return ''
      },
      locationToName() {
        if (this.movement.toLocationId) {
          return this.locations.filter((x) => x.id == this.movement.toLocationId)[0].name
        }
        return ''
      },
      //minimumMovementDate() {
      //  if (this.movement.mode == Enums.MovementModes.Arrival.value) {
      //    const movementDate = new Date(Date.parse(this.movement.outMovementDate))
      //    return `${movementDate.getFullYear()}-${('0' + (movementDate.getMonth() + 1)).slice(-2)}-${('0' + movementDate.getDate()).slice(-2)}`
      //  }
      //  return ''
      //},
      minimumArrivalDate() {
        if (this.movement.departureDate) {
          const date = new Date(Date.parse(this.movement.departureDate))
          return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
        }
        return ''
      },
      maximumArrivalTime() {
        if (this.movement.departureDate && this.movement.departureTime) {
          var departureDate = new Date(Date.parse(this.movement.departureDate))
          if (departureDate.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)) {
            today = new Date()
            return today.toLocaleTimeString('en-GB');
          }
        }
        return '23:59'
      },
      maximumDate() {
        const today = new Date()
        return `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`
      },
      //minimumMovementTime() {
      //  if (this.movement.mode == Enums.MovementModes.Arrival.value && this.movement.date) {
      //    let movementDate = new Date(Date.parse(this.movement.date))
      //    movementDate.setHours(0, 0, 0, 0);
      //    let outMovementDate = new Date(Date.parse(this.movement.outMovementDate))
      //    const clonedOutMovementDate = new Date(outMovementDate.getTime());
      //    outMovementDate.setHours(0, 0, 0, 0)
      //    if (movementDate.getTime() == outMovementDate.getTime()) {
      //      return clonedOutMovementDate.toLocaleTimeString('en-GB');
      //    //  return `${('0' + (clonedOutMovementDate.getHours())).slice(-2)}:${('0' + (clonedOutMovementDate.getMinutes())).slice(-2)}`
      //    }
      //  }
      //  return ''
      //},
      activeLocationsWithoutLocationFrom() {
        const self = this
        return self.activeLocations.filter(ml => ml.id != self.movement.fromLocationId)
      },
      //maximumTime() {
      //  if (this.movement.date) {
      //    let today = new Date()
      //    var movementDate = new Date(Date.parse(this.movement.date))
      //    if (movementDate.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)) {
      //      today = new Date()
      //      return today.toLocaleTimeString('en-GB');
      //    }
      //  }
      //  return '23:59'
      //},
      availableSupportingDocuments() {
        return this.movement.supportingDocuments.filter((document) => !document.isDeleted)
      },
      //sortedRates() {
      //  return this.allRates.sort((x, y) => {
      //    const dateX = new Date(x.startDate)
      //    const dateY = new Date(y.startDate)

      //    if (dateX > dateY) {
      //      return -1;
      //    }
      //    if (dateX < dateY) {
      //      return 1;
      //    }
      //    return 0;
      //  })
      //},
      //passengerRate() {
      //  return this.getRatesForType(0)
      //}
    },
    methods: {
      removeDocument(documentId) {
        for (let i = 0; i < this.movement.supportingDocuments.length; i++) {
          if (this.movement.supportingDocuments[i].id === documentId) {
            this.movement.supportingDocuments[i].isDeleted = true
            break
          }
        }
      },
      downloadDocument(voyageId, movementId, documentId, documentName) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, 'downloading document')
        voyageService.downloadDocument(voyageId, movementId, documentId).then(function (data) {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', documentName)
          document.body.appendChild(link)
          link.click()
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        });
      }
      //getRatesForType(rateType) {
      //  const movementDate = new Date(this.movement.movementDate)
      //  const matchingRates = this.sortedRates.filter(r => r.rateType == rateType && movementDate >= new Date(r.startDate))
      //  if (matchingRates.length) {
      //    return matchingRates[0].value
      //  }
      //  return 0
      //}
    },
    mounted() {
      NextPage.components.drawers('.drawers')
      const self = this
      eventBus.$on(self.chargeCodeSearchClearedEvent, () => {
        self.movement.chargeCode = ''
      })
      eventBus.$on(self.chargeCodeSearchSelectedEvent, (selectedChargeCode) => {
        self.movement.chargeCode = selectedChargeCode.costCenter
      })
      eventBus.$on(self.chargeCodeSearchStartedEvent, (searchString) => {
        self.movement.chargeCode = ''
        self.searchedChargeCodes.splice(0, self.searchedChargeCodes.length)
        chargeCodeService.searchChargeCodes(searchString)
          .then((result) => {
            if (result.data && result.data.length) {
              self.searchedChargeCodes = [...result.data]
            }
          })
      })
    },
    beforeDestroy() {
      eventBus.$off(this.chargeCodeSearchClearedEvent)
      eventBus.$off(this.chargeCodeSearchStartedEvent)
      eventBus.$off(this.chargeCodeSearchSelectedEvent)
    }
  }
</script>
