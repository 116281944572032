<template>
  <div>
    <!--<table :id="id" class="display table table-condensed" style="width:100% !important; border-collapse:collapse;">-->
      <table :id="id" class="display table table-condensed" style="width:100% !important; border-collapse:collapse;">
        <thead>
          <tr>
            <!--<th width="2%"></th>-->
            <!--<th></th>-->
            <th>Vessel</th>
            <th>Mode</th>
            <th>From</th>
            <th>To</th>
            <th>Arrival date</th>
            <th>Arrival time</th>
            <th>Departure date</th>
            <th>Departure time</th>
            <th>Vessel type</th>
            <th>Vessel grt</th>
            <th>User Department</th>
            <th>Fuel tonnage</th>
            <th>Chargeable tonnage</th>
            <th>POB</th>
            <th>Pilotage</th>
            <th>Conservancy</th>
            <th>Footage</th>
            <th>Light dues</th>
            <th>Weekend / public holiday</th>
            <th>Overtime</th>
            <th>Barge</th>
            <th>Barge Tonnage</th>
            <th>Barge CMB</th>
            <th>Cargo Tonnage</th>
            <th>Charge Code</th>
            <th>Garbage scavending</th>
            <th>Total(before VAT)</th>
            <th>VAT</th>
            <th>Total(after VAT)</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(movement, index) in movements">
            <tr>
              <!--<td>{{ index + 1 }}</td>-->
              <!--<td></td>-->
              <td>{{ movement.vessel.name }}</td>
              <td>{{ movement.mode | toMovementModeDescription  }}</td>
              <td>{{ movement.fromLocationName }}</td>
              <td>{{ movement.toLocationName }}</td>
              <td>{{ movement.departureDate | toDateAtTime}}</td>
              <td>{{ movement.departureDate | onlyTime }}</td>
              <td>{{ movement.arrivalDate | toDateAtTime }}</td>
              <td>{{ movement.arrivalDate | onlyTime }}</td>
              <td>{{ movement.vessel.vesselTypeName}}</td>
              <td>{{ movement.vessel.grt}}</td>
              <td>{{ movement.userGroup.name}}</td>
              <td>{{ movement.fuelTonnage | toWholeNumberDisplay}}</td>
              <td>{{ movement.chargeableTonnage }}</td>
              <td>{{ movement.pobCharge | toNumericDisplay }}</td>
              <td>{{ (movement.pilotageCharge1 + movement.pilotageCharge2) | toNumericDisplay }}</td>
              <td>{{ movement.conservancyCharge | toNumericDisplay }}</td>
              <td>{{ movement.footageCharge | toNumericDisplay }}</td>
              <td>{{ movement.lightDues | toNumericDisplay }}</td>
              <td>{{ movement.weekendOrPublicHolidayCharge | toNumericDisplay }}</td>
              <td>{{ movement.overtimeCharge | toNumericDisplay }}</td>
              <td>
                <span v-if="movement.barge && movement.barge.name">{{ movement.barge.name }}</span>
                <span v-else>___</span>
              </td>
              <td>
                <span v-if="movement.barge && movement.barge.name">{{ movement.barge.tonnage }}</span>
                <span v-else>___</span>
              </td>
              <td>
                <span v-if="movement.barge && movement.barge.name">{{ movement.barge.cbm }}</span>
                <span v-else>___</span>
              </td>
              <td>{{ movement.materialCargoTonnage | toWholeNumberDisplay  }}</td>
              <td>{{ movement.chargeCode }}</td>
              <td>{{ movement.garbageScavendingCharge | toNumericDisplay }}</td>
              <td>{{ movement.totalBeforeVAT | toNumericDisplay }}</td>
              <td>{{ movement.vat | toNumericDisplay }}</td>
              <td>{{ movement.totalAfterVAT | toNumericDisplay }}</td>
            </tr>
          </template>
        </tbody>
      </table>
  </div>
</template>

<style lang="scss" scoped>
  .action-btn {
    cursor: pointer;
    max-width: 40% !important;
  }

  /*table tbody tr:nth-child(odd) td {
    background-color: #ffffff !important;
  }*/

  tr.group {
    background-color: #f3f4f8 !important;
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
</style>

<script>
  import { mapState } from 'vuex'

  import Enums from '@/utils/enums'

  import { toDateAtTime, toNumericDisplay, onlyTime, toWholeNumberDisplay } from '@/filters'
  import { toStatusDescription, toMovementStatusDescription, toMovementModeDescription } from '@/filters/enum'

  import eventBus from '@/utils/eventBus'
  import { ADD_INWARDMOVEMENT_EVENT, EDIT_MOVEMENT_EVENT } from '@/utils/constants'

  export default {
    name: 'monthly-movement-list',
    components: {
    },
    props: {
      id: {
        type: String,
        required: false,
        default: 'movementList'
      },
      movements: {
        type: Array,
        required: true
      },
      tableProps: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      Enums: Enums,
      tableObject: null,
      toMovementStatusDescription
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser
      }),
    },
    watch: {
      //movements: {
      //  handler(newmovements) {
      //    const self = this
      //    self.$nextTick(() => {
      //      self.refresh();
      //    });
      //  },
      //  immediate: true
      //},
    },
    filters: {
      toStatusDescription,
      toMovementStatusDescription,
      toMovementModeDescription,
      toDateAtTime,
      onlyTime,
      toNumericDisplay,
      toWholeNumberDisplay
    },
    methods: {
      refresh() {
        const self = this;
        if (self.tableObject) {
          self.tableObject.destroy();
          $(`#${self.id}`).empty()
        }
        var groupColumn = 0;
        self.tableObject = $(`#${self.id}`).DataTable({
          language: {
            emptyTable: "There are no movements to display for this location"
          },
          searching: true,
          search: {
            regex: false,
            smart: false
          },
          paging: true,
          info: true,
          //columnDefs: [{ visible: false, targets: groupColumn }],
          //order: (self.tableProps && self.tableProps.order ? self.tableProps.order : []),
          //pageLength: (self.tableProps && self.tableProps.page ? self.tableProps.page.length : 10),
          //displayStart: (self.tableProps && self.tableProps.page ? self.tableProps.page.length * self.tableProps.page.page : 0),
          //search: (self.tableProps && self.tableProps.search ? self.tableProps.search : null),
          dom: 'lBfrtip',
          buttons: [
            {
              extend: 'csv',
              footer: false,
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,25,26,27,28]
              }
            },
            {
              extend: 'excel',
              footer: false,
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,25,26,27,28]
              }
            }
          ],
          //drawCallback: function (settings) {
          //  var api = this.api();
          //  var rows = api.rows({ page: 'current' }).nodes();
          //  var last = null;

          //  api
          //    .column(groupColumn, { page: 'current' })
          //    .data()
          //    .each(function (group, i) {
          //      if (last !== group) {
          //        const text = group.substring(0, group.lastIndexOf('-'))
          //        $(rows)
          //          .eq(i)
          //          .before('<tr class="group"><td colspan="15">' + text + '</td></tr>');
          //        last = group;
          //      }
          //    });
          //},
        });


        if (self.tableProps.order && self.tableProps.order.length) {
          self.tableObject.order(self.tableProps.order).draw()
        }

        if (self.tableProps.search) {
          self.tableObject.search(self.tableProps.search).draw()
        }

        $(`#${self.id}`).on('order.dt', function (e, settings) {
          if (self.tableObject.order()) {
            self.tableProps.order = self.tableObject.order()
          }
        });

        $(`#${self.id}`).on('search.dt', function () {
          self.tableProps.search = self.tableObject.search();
        });
      }
    },
    mounted() {
    //  this.refresh()
    },
    beforeDestroy() {
      if (this.tableObject) {
        this.$nextTick(() => {
          this.tableObject.destroy();
        });
      }
    }
  }
</script>
